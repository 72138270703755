.red-level {
  stroke: #e74c3c;
  border-color: #e74c3c;
  color: #e74c3c;
}

.orange-level {
  stroke: #f39c12;
  border-color: #f39c12;
  color: #f39c12;
}

.blue-level {
  stroke: #140a7d;
  border-color: #140a7d;
  color: #140a7d;
}

.green-level {
  stroke: #4caf50;
  border-color: #4caf50;
  color: #4caf50;
}

.unknown-level {
  stroke: #cccccc;
  border-color: #cccccc;
  color: #cccccc;
}
